@import "./custom-media.css";

.header-banner {
  background-color: var(--bern-black);
  color: #ffffff;
  margin-bottom: 5rem;
  padding: 5.75rem 0;
}

.header-banner__title-outer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2.75rem;
}

.header-banner__title {
  hyphens: auto;
  margin-bottom: 1.875rem;
}

.svg-icon + .header-banner__title {
  margin-left: 1rem;
}

.header-banner__parts {
  display: flex;
  flex-wrap: wrap;
  gap: 5rem;
  align-items: flex-start;
}

.header-banner__content--left {
  flex: 2 2 50%;
}

.header-banner__content--right {
  flex: 0 0 344px;
  justify-content: flex-end;
  display: flex;
  max-width: 100%;
}

.header-banner .card {
  padding-bottom: 1.75rem;
}

.header-banner--services {
  background-color: var(--bern-yellow);
  color: var(--bern-black);

  .header-banner__title {
    margin-bottom: 0;
  }

  .header-banner__content--left {
    padding-top: 3.5rem;
  }
  .round-icon {
    flex: 0 0 var(--size);
  }
}

.header-banner__description {
  font-weight: 600;
  font-size: var(--fs-large);
}

.header-banner--tg {
  background-color: var(--bern-red);

  .round-icon {
    margin-bottom: 1.438rem;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header-banner__content--left {
    padding-top: 1.125rem;
  }
}

@media (--media-sm-only) {
  .header-banner {
    margin-bottom: 5rem;
    padding: 2.8rem 0;
  }

  .header-banner__title-outer {
    margin-bottom: 2rem;
  }

  .header-banner__parts {
    gap: 2.5rem;
  }

  .header-banner--services .header-banner__content--left {
    padding-top: 0;
  }
}

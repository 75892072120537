@import "./custom-media.css";

.footer {
  background: var(--bern-black);
  padding: 3.125rem 1rem 1rem;
  position: relative;
  color: #cccccc;
  margin-top: 10rem;

  & a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: var(--bern-red);
    }
  }

  & strong {
    display: block;
    color: #ffffff;
  }
}

.footer__parts {
  display: grid;
  grid-template-columns: repeat(10, minmax(0, 1fr));
  grid-gap: var(--grid-gutter);
  gap: var(--grid-gutter);
}

.footer__the-end {
  border-top: 1px solid #808080;
  display: flex;
  flex-wrap: wrap;
  gap: 1.875rem;
  margin-top: 1.875rem;
  padding: 1.875rem 0 1.25rem 0;
}

.footer__menu {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  > a {
    padding: 0 0 0 0.875rem;
  }

  .active {
    text-decoration: underline;
  }
}

.footer__address {
  & address {
    font-style: normal;

    @media (--media-md) {
      margin-top: 3.75rem;
    }

    @media (--media-lg) {
      margin-top: 3rem;
    }
  }

  grid-column: 1 / span 3;
  > * {
    margin-bottom: 1.5rem;
  }
  > .logo {
    color: #ffffff;
  }
}

.footer__logo {
  height: 38px;
  margin-top: 10px; /* correction to font logo */
}

.footer__reachability {
  grid-column: 4 / span 3;
  padding-top: 2rem;

  & p {
    margin-top: 1rem;
  }
}

.footer__consulting {
  grid-column: 8 / span 3;
  padding-top: 3.125rem;

  @media (--media-md) {
    padding-top: 4.5rem;
  }

  @media (--media-lg) {
    padding-top: 3.5rem;
  }

  > strong {
    margin-bottom: 1rem;
  }
}

.footer__collaboration {
  display: grid;
  grid-template-columns: repeat(10, minmax(0, 1fr));
  gap: 1rem var(--grid-gutter);
}

.footer__collaboration__title-container {
  grid-column: span 3;
}

.footer__collaboration__item {
  grid-column: span 2;
}

@media (--media-mobile) {
  .footer {
    margin-top: 5rem;
  }
  .footer__parts {
    display: block;

    > div {
      padding-top: 1rem;
    }
  }

  .footer__collaboration {
    display: flex;
    flex-wrap: wrap;
    gap: 2em;

    > * {
      width: 100%;
    }
  }
}

@import "./custom-media.css";

.services__button-group {
  overflow-x: hidden;
  margin-bottom: 2.25rem;
  display: flex;
  flex-wrap: wrap;
  column-gap: 1.25rem;
  gap: 1.25rem;
}

.body--services {
  & .schema {
    row-gap: calc(var(--grid-gutter) / 2);
  }

  & figure {
    @media (--media-sm-only) {
      margin-top: var(--grid-gutter);
    }
  }

  & .container {
    margin-bottom: 3rem;
  }

  & .footer {
    margin-top: 4rem;
  }
}

.services__button {
  color: inherit;
  text-decoration: none;

  border: 2px solid var(--bern-red);
  border-radius: 20px;
  padding: 0.375rem 1.375rem;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  font-size: var(--fs-medium);
  cursor: pointer;
  line-height: 1em;
  scroll-margin-top: 12rem;

  & svg {
    height: 24px;
    width: 24px;
    margin-right: 12px;
  }

  &:hover {
    background: var(--bern-red);
    color: #ffffff;
  }

  &.active {
    background: var(--bern-red);
    color: #ffffff;
  }
}

@media (--media-lg) {
  .services {
    --grid-gutter: 2.75rem;
  }
}

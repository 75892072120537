.logo {
  font-size: 20px;
  line-height: 1.1em;

  & strong {
    display: block;
    text-transform: uppercase;
    color: var(--bern-red);
  }
}

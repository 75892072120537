@import "./custom-media.css";

* {
  line-height: 1.4em;
}

html {
  scroll-behavior: smooth;
  overscroll-behavior: none;
}

body {
  position: relative;
  font-family: Montserrat, Avenir, Corbel, "URW Gothic", source-sans-pro,
    sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  color: var(--fg);
  background: var(--bg);
}

.body {
  min-height: 100vh;
  display: flex;
  flex-flow: column;
}

.main {
  flex: 1 0 auto;

  > .container {
    margin-bottom: 10rem;

    &:last-child {
      margin-bottom: 0;
    }

    &:first-child {
      margin-top: 8.125rem;
    }
  }

  > .container--title {
    margin-bottom: 2.5rem;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  hyphens: auto;
}

h1 {
  font-size: 44px;
}

h2 {
  font-size: 26px;
  margin-bottom: 1.462em;
}

h3 {
  font-size: 24px;
}

@media (--media-mobile) {
  .main > .container {
    margin-bottom: 5rem;

    &:first-child {
      margin-top: 4rem;
    }
  }

  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 22px;
  }
}

figcaption {
  margin-top: 1rem;
}

@import "./custom-media.css";

.collaboration__items {
  display: flex;
  grid-gap: var(--grid-gutter);
  gap: var(--grid-gutter);
  flex-wrap: wrap;
}

.collaboration__item {
  background-color: var(--bern-white);
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  flex: 1;
  overflow: hidden;
}

.collaboration__item-image {
  background-color: white;
  padding: 2.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 15rem;
}

.collaboration__item-content {
  padding: 2.375rem 2.5rem 2.125rem 2.375rem;
}

.collaboration__item-text {
  font-weight: 500;
}

.collaboration__item-title {
  margin-bottom: 1rem;
}

.collaboration__item-text {
  font-weight: 500;
}

@media (--media-lg) {
  .collaboration__items {
    --grid-gutter: 2.75rem;
  }
}

@media (--media-mobile) {
  .collaboration__item {
    flex: 1 1 100%;
  }
}

.customers {
  --fg: #333;
  --bg: #f1f1f3;
  --border: 1px solid var(--fg);
}

.customers__customer {
  aspect-ratio: 1/1;
  background: #fff center no-repeat;
  background-size: 80%;
}

@import "./custom-media.css";

.schema {
  display: grid;
  grid-template-columns: repeat(10, minmax(0, 1fr));
  grid-gap: var(--grid-gutter);
  gap: var(--grid-gutter);

  .prose {
    margin-top: 1rem;
  }
  & h1 {
    margin-bottom: 3.125rem;
  }

  /* & p:not(:last-child) {
    margin-bottom: 3.125rem;
  } */

  & img {
    filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15));
    border-radius: var(--radius);
  }
}

.schema--directive-two-cells-same-size {
  @media (--media-lg) {
    --grid-gutter: 3.75rem;
  }
}

@media (--media-lg) {
  .cell--image {
    margin-top: 2rem;
  }
}

@media (--media-lg) {
  .schema--directive-large-text-left-image-right .cell--image {
    grid-column-start: 8;
  }
}

@media (--media-sm-only) {
  .schema .cell--image img {
    width: 100%;
  }
}

body {
  --f3cc-background: #666;
  --f3cc-foreground: var(--white);
  --f3cc-button-background: #888;
  --f3cc-accept-background: var(--bern-yellow);
  --f3cc-accept-foreground: var(--fg);
}

.f3cc-button + .f3cc-button {
  margin: 1rem 0 0;
}

@import "./custom-media.css";

.team:not(:last-child) {
  margin-bottom: 4.75rem;
}

.team__member {
  box-shadow: var(--shadow);
  border-radius: var(--radius);
  overflow: hidden;
}

.team__image {
  aspect-ratio: 1.4 / 1;
  background-size: cover;
  position: relative;
  overflow: hidden;
  background-image: var(--image);
}

@keyframes huePosition {
  0% {
    background-position: 5% 95%;
  }
  50% {
    background-position: 95% 5%;
  }
  100% {
    background-position: 5% 95%;
  }
}

.team__image--none {
  background: var(--bern-yellow);
  background-size: 200% 200%;
  background-image: linear-gradient(
    45deg,
    var(--bern-yellow) 0%,
    var(--bern-red) 100%
  );

  animation: 60s infinite huePosition;

  .team__contact {
    left: 0;
  }
}

@media (--media-sm-only) {
  .team__image {
    aspect-ratio: 400 / 350;
    background-position: center;
  }
}

.team__info {
  padding: 1.5rem 2rem 2.5rem;
}

.team__category {
  font-weight: bold;
}

.team__name {
  margin: 0.5em 0 0.75em;
}

.team__title {
  margin-bottom: 1.5rem;
}

@media (--media-lg) {
  .team {
    --grid-gutter: 2.75rem;
  }

  .team__title {
    margin-bottom: 3.125rem;
  }
}

.icon {
  --size: 1rem;
  width: var(--size);
  height: var(--size);
}

.svg-icon,
.svg-icon svg {
  --size: 3rem;
  width: var(--size);
  height: var(--size);
}

.round-icon {
  --size: 3rem;
  --color: var(--bern-black);
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  background-color: var(--bern-black);
  color: #ffffff;
}

.round-icon--red {
  background-color: var(--bern-red);
  color: #ffffff;
}

.round-icon--yellow {
  background-color: var(--bern-yellow);
  color: var(--bern-black);
}

.round-icon--white {
  background-color: #ffffff;
  color: var(--bern-black);
}

.round-icon--large,
.svg-icon--large,
.svg-icon--large svg {
  --size: 4.375rem;
}

.round-icon--small {
  --size: 2rem;
}

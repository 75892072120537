.buttons {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-end;
  column-gap: 1rem;
}

button {
  padding: 0;
  overflow: visible;
  border: 0;
  font: inherit;
  font-size: inherit;
  -webkit-font-smoothing: inherit;
  letter-spacing: inherit;
  color: inherit;
  background: none;
  cursor: pointer;
  appearance: none;
}

.button {
  display: inline-flex;
  gap: 0.5rem;
  user-select: none;
  line-height: 1em;
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
}

a.button {
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 0;
  color: var(--bern-red);
  border: 2px solid var(--bern-red);
  border-radius: var(--radius-small);
  font-weight: bold;
  white-space: nowrap;
  line-height: 1em;
  padding: 0.875rem 1.5rem;
  position: relative;

  &::after {
    content: "";
    background-image: url(static/assets/arrow-right-red.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 1.25rem;
    width: 0.75rem;
    margin-left: 1rem;
  }

  &:hover {
    text-decoration: none;
    color: #ffffff;
    background-color: var(--bern-red);
    &::after {
      background-image: url(static/assets/arrow-right-white.svg);
    }
  }
}

.button--submit {
  color: var(--bern-red);
}

.button--external {
  box-shadow: var(--shadow);
  border-color: #fff;
  color: var(--bern-black);

  &::after,
  &:hover::after {
    background-image: url(static/assets/external.svg);
    color: inherit;
    width: 21.5px;
    height: 21.5px;
  }

  &:hover {
    background: #fff;
    color: var(--bern-red);
    border-color: var(--bern-red);

    &::after {
      background-image: url(static/assets/external.svg);
      width: 21.5px;
      height: 21.5px;
    }
  }
}

:root {
  --black: #333;
  --white: #fff;

  --fg: var(--bern-black);
  --bg: var(--white);

  --bern-yellow: #ffc02a;
  --bern-red: #c6232e;
  --bern-black: #252731;
  --bern-white: #fbfbfb;

  --error-fg: #c6232e;
  --warning: orange;
  --info: darkblue;
  --success: darkgreen;

  --border: 1px solid var(--fg);
  --shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.15);
  --radius: 0.75rem;
  --radius-small: 0.25rem;

  --z-top: 5;
  --z-menu: 3;

  /* Font sizes  */
  --fs-small: 14px;
  --fs-regular: 16px;
  --fs-medium: 18px;
  --fs-large: 20px;
}

.hide {
  display: none !important;
}

.sr-only {
  width: 0px;
  height: 0px;
  position: absolute;
  overflow: hidden;

  &.skip-link:focus-visible {
    height: auto;
    width: auto;
    left: 5rem;
    top: 5rem;
  }
}

.print-only {
  display: none;

  @media print {
    display: unset;
  }
}

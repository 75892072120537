@import "./custom-media.css";

.grid--neck {
  gap: var(--grid-gutter);
}

@media (--media-md) {
  .grid--neck > .cell {
    grid-column: 2 / span 10;
  }
}

.section {
  display: grid;
  grid-template-columns: repeat(var(--grid-columns), minmax(0, 1fr));
}

.section__content {
  margin-bottom: -5rem;
  display: grid;
  grid-column: 1 / span 12;
  overflow-wrap: break-word;
  hyphens: auto;

  @media (--media-md) {
    gap: 6rem;
    grid-column: 2 / span 10;
    grid-template-columns: 3fr 10fr;
  }
}

.cell__gradient {
  color: white;
  position: relative;
}

.cell__gradient::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--gradient);
  border-radius: var(--radius);
  animation: var(--hue-rotate);
  z-index: -1;
}

@keyframes gridReveal {
  to {
    opacity: 1;
  }
}

.messages__message {
  padding: 0.5rem 1rem;
  border: 2px solid;
  border-radius: var(--radius);
  margin-bottom: 0.5rem;

  &.error {
    border-color: var(--error-fg);
  }

  &.info {
    border-color: var(--info);
  }

  &.success {
    border-color: var(--success);
  }

  &.warning {
    border-color: var(--warning);
  }
}

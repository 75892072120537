@import "./custom-media.css";

.faq {
  background: var(--bern-red); /* FIXME depends on where FAQ is embedded */
  border-radius: var(--radius);
  padding: 2.125rem 0;

  > .cell {
    grid-column: 2 / span 10;

    @media (--media-md) {
      grid-column: 3 / span 8;
    }
  }
}
.faq__item {
  background: #fff;
  border-radius: var(--radius);
}

.faq__question {
  font-size: var(--fs-large);
  font-weight: bold;
  padding: 0 7rem 0 2.25rem;
  cursor: pointer;
  position: relative;
  height: var(--collapsed, 5rem);
  display: flex;
  align-items: center;

  &::after {
    content: "";
    position: absolute;
    top: 2rem;
    right: 3.5rem;
    background-image: url(static/assets/arrow-right-red.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 1.25rem;
    width: 1.25rem;
    transition: transform 0.3s;
  }
}

.faq__item[open] .faq__question::after {
  transform: rotate(90deg);
}

.faq__item + .faq__item {
  margin-top: 0.75rem;
}

.faq__answer {
  padding: 0 7rem 2.625rem 2.25rem;
  font-size: var(--fs-medium);
}

@media (--media-mobile) {
  .faq {
    padding: 1.75rem 0;
  }

  .faq__question {
    padding: 0 3rem 0 1rem;
    height: var(--collapsed, 4.8rem);
    font-size: var(--fs-regular);

    &::after {
      right: 1rem;
      height: 1rem;
      width: 1rem;
    }
  }

  .faq__answer {
    padding: 0 1rem 1rem 1rem;
    font-size: var(--fs-regular);
  }
}

@import "./custom-media.css";

.body {
  padding-top: 6.875rem;
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: var(--bg);
  overflow: hidden;

  box-shadow: var(--shadow);

  z-index: var(--z-menu);
}

.menu__parts {
  display: flex;
  gap: 1rem;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1.938rem 0;
}

.menu__toggle {
  width: 1.75rem;
  height: 1.5rem;
  cursor: pointer;
  display: none;
}

.menu__close {
  display: none;
  --size: 2rem;
}

.menu__open {
  --size: 2rem;
}

#expand-menu {
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  position: absolute;
}

#expand-menu:checked {
  + .menu {
    z-index: var(--z-top);
    transition:
      width 0.3s ease-out,
      height 0.3s ease-out;

    .menu__close {
      display: block;
    }
    .menu__open {
      display: none;
    }
  }
}

.menu__logo {
  height: 38px;
}

.menu__menu {
  display: flex;
  flex-flow: row wrap;

  justify-content: center;
  text-align: center;
  /* overflow: hidden; */

  & a {
    color: inherit;
    text-decoration: none;
    white-space: nowrap;
    text-transform: Uppercase;
    font-weight: bold;
    line-height: 1em;
    display: block;
    padding: 1rem 0; /* Clickable area should be bigger */
    margin-right: 3.75rem;
  }

  & a:hover,
  & a:focus-visible {
    color: var(--bern-red);
  }

  .active {
    text-decoration: none;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 2px;
      height: 7px;
      background: url(static/assets/arc_menu.svg) no-repeat center top;
      background-size: 100% 100%;
    }
  }
}

.menu__social {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.75rem;
  width: var(--menu-width);
  margin: auto 0 1rem;

  & a {
    color: inherit;
    text-decoration: none;
    display: grid;
    place-items: center;
  }
}

.menu__submenu {
  padding: 0;
  transition: padding 0.5s ease-in-out;

  overflow: hidden;
}

.menu__services nav {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.25rem 1rem;
  padding: 1.375rem 0;
}

.menu__services-title {
  display: inline-block;
  position: relative;
  top: -0.625rem;
}

.menu__services-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-weight: 700;
  gap: 1.125rem;

  /* Old browsers... */
  text-decoration: none;
  color: inherit;

  & svg {
    width: 2em;
    height: 2em;
  }

  > span {
    flex: 1 1 calc(100% - 3.625rem);
  }

  &:hover {
    color: var(--bern-red);
  }
}

#submenu {
  /* display: none; */

  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.5s ease-in-out;

  &:hover {
    display: grid;
  }
}

#submenu.open {
  grid-template-rows: 1fr;
  .menu__submenu {
    padding: 1.5rem 0;
  }
}

@media (--media-mobile) {
  .body {
    padding-top: 5rem;
  }

  .menu__toggle {
    display: block;
  }

  .menu {
    height: 5rem;
  }

  .menu__parts {
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1rem 0;
  }

  .menu__menu {
    flex-basis: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 0;

    & a {
      padding: 1rem 0;
      position: relative;
    }

    & a[data-type="service-list"] {
      padding-right: 1.5rem;

      &::before {
        content: "";
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        position: absolute;
        background-image: url(static/assets/arrow-right-red.svg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 1.25rem;
        width: 0.75rem;
        transition: transform 0.5s ease-out;
      }
    }

    & a.rotate::before {
      transform: rotate(90deg) translateX(-100%);
    }
  }

  .menu__submenu {
    grid-template-columns: 1fr;
    padding: 0;
  }

  .menu__services {
    padding-top: 1.5rem;
  }

  .menu__target-groups {
    grid-row: 1;
    padding-left: 1rem;
    margin-bottom: 1rem;
  }

  #expand-menu:checked {
    + .menu {
      height: 95vh;
      overflow: auto;

      .menu__close {
        display: block;
      }
      .menu__open {
        display: none;
      }
    }
  }
}

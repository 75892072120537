@import "./custom-media.css";

.edit-tools {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  background-color: #ffffff;
  display: inline-block;
  color: var(--bern-red);
  border: 2px solid var(--bern-red);
  border-radius: var(--radius-small);
  font-weight: bold;
  text-decoration: none;
  white-space: nowrap;
  line-height: 1em;
  padding: 0.5rem 0.75rem;

  &:hover {
    text-decoration: none;
    color: #ffffff;
    background-color: var(--bern-red);
  }

  & a {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

#djDebug {
  display: none;
}

@media (--media-lg) {
  #djDebug {
    display: block;
  }
}

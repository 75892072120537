@import "./custom-media.css";

.prose {
  & :is(h1, h2, h3, h4, h5, h6) {
    margin-bottom: 1rem;
  }

  &:not(:first-child) :is(h1, h2, h3, h4, h5, h6) {
    margin-top: 2rem;
  }

  & ul {
    margin-block: unset;
  }
}

.prose * {
  hyphens: auto;
}

.prose a {
  color: var(--bern-red);
  transition: color 0.5s linear;
  cursor: pointer;

  &:hover,
  &:focus {
    color: var(--bern-yellow);
  }
}

.prose figure img {
  margin: 0 auto;
}

.image--standalone {
  @media (--media-md) {
    grid-column: 2 / span 10;
  }

  & img {
    border-radius: var(--radius);
  }
}

.responsive-embed {
  margin-top: 2rem;
}

.prose + .button {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.prose .table-wrapper {
  overflow-x: auto;
}

.prose table {
  min-width: 100%;
  font-size: var(--fs-small);

  border: var(--border);
  border-collapse: separate;
  border-radius: var(--radius);
  border-spacing: 0;

  overflow: hidden;

  @media (--media-md) {
    font-size: var(--fs-medium);
  }
}

.prose table * {
  hyphens: none;
}

.prose :is(td, th) {
  text-align: left;
  background: var(--bg);
  box-shadow: 0 0 0 1px var(--fg);
}

.prose th {
  padding: 1rem;
  background-color: var(--bern-yellow);
}

.prose td {
  padding: 0.5rem;
}

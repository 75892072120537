@import "./custom-media.css";

.personal-advice {
  background-color: var(--bern-yellow);
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  padding: 2.875rem;
  padding-bottom: 25%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  column-gap: 2rem;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  max-width: 100%;

  &::after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    background: url(static/assets/berner_skyline.svg) left bottom no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    mix-blend-mode: multiply;
    box-sizing: content-box;
    aspect-ratio: 525 / 71;
    pointer-events: none; /* Fix interaction with button */
  }
}

.personal-advice__content {
  font-size: var(--fs-medium);
}

.personal-advice__title {
  margin-bottom: 1.5rem;
  line-height: 1em;
}

@media (--media-lg) {
  .personal-advice {
    flex-wrap: nowrap;
    padding-bottom: 14%;
  }
}

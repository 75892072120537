.form {
  --_hp: 1.5rem;

  .widget--radioselect > div,
  .widget--checkboxselectmultiple > div {
    list-style: none;
    padding: 0;
  }

  .errors {
    display: flex;
    align-items: center;
    line-height: 1.3em;

    margin: 1rem 0;
    margin-bottom: 1rem;

    color: var(--error-fg);
  }

  .errorlist {
    color: var(--error-fg);
    padding: 0 var(--_hp);
  }

  .buttons {
    margin: 1rem 0;
  }
}

.field {
  position: relative;
  padding: 1rem 0;

  &:not(.widget--checkboxinput) > label {
    --_lp: 0.25rem;

    position: absolute;
    left: calc(var(--_hp) - var(--_lp));
    top: 6px;

    background: #fff;
    padding: 0 var(--_lp);
    display: block;
    font-size: var(--fs-small);
    font-weight: bold;
  }

  > label.required::after {
    content: " *";
  }

  & input[type="text"],
  & input[type="email"],
  & input[type="number"],
  & select,
  & textarea,
  & .grow-wrap > textarea,
  & .grow-wrap::after {
    display: block;
    width: 100%;
    border: 1px solid;
    border-radius: 1rem;
    padding: 1rem var(--_hp);
    background: #fff;
  }
}

.field.is-invalid {
  & input[type="text"],
  & input[type="email"],
  & input[type="number"],
  & select,
  & textarea {
    border-color: var(--error-fg);
  }
}

.widget--select select {
  appearance: none;
}

.field__help {
  padding: 0.25rem var(--_hp) 0;
}

.widget--radioselect {
  > div {
    display: flex;
    gap: 1rem 1.5rem;
    flex-flow: row wrap;
    margin: 1rem 0;
  }

  & input {
    display: none;
  }

  & div label {
    position: relative;
    cursor: pointer;
    margin-left: 1.5rem;

    background: url(static/assets/radio_button_unchecked.svg) no-repeat 0 center;
    background-size: contain;

    padding-left: 2.5rem;

    height: 1.5rem;
    display: flex;
    align-items: center;
  }

  & div input:checked + label {
    background-image: url(static/assets/radio_button_checked.svg);
  }
}

.widget--uploadfileinput {
  & label {
    position: static !important;
  }

  & input {
    margin: 0.5rem 0 0 0.25rem;
  }
}

.widget--hiddeninput {
  display: none;
}

.tg {
  text-align: center;
  background: var(--bern-black);
  color: #fff;
  padding: 3rem 1rem 2.25rem 1rem;
  border-radius: var(--radius);

  > h2 {
    margin-bottom: 1.5rem;
  }
}

.tg__groups {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
}

.tg__group {
  background: #fff;
  color: var(--bern-black);
  padding: 1.875rem;
  border-radius: var(--radius-small);
  display: inline-grid;
  place-items: center;

  /* Temp */
  &::before {
    content: "";
    height: 30px;
    width: 30px;
    border: 2px solid var(--bern-black);
    border-radius: 25%;
    margin-bottom: 1rem;
  }
}

.tg__group h3 .icon {
  color: var(--bern-red);
}

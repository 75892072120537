.card {
  text-decoration: none;

  overflow-wrap: break-word;
  hyphens: auto;
  color: var(--bern-black);
  display: flex;
  flex-direction: column;

  box-shadow: var(--shadow);
  border-radius: var(--radius);

  padding: 97px 4.25rem 2.375rem 2.375rem;

  transition: transform 0.4s;
  position: relative;

  & svg {
    position: absolute;
    top: 54px;
    left: 2.375rem;
    height: 48px;
    width: 48px;
    transform: translateY(-50%);
  }
}

.card__content {
  flex: 1 0 auto;
}

.card__title {
  line-height: 1.25em;
  margin-bottom: 0.75rem;
}

.card__more {
  margin-top: 2rem;
  color: var(--bern-red);
  font-weight: bold;
  display: flex;
  align-items: center;
  max-width: 100%;

  &::after {
    content: "";
    display: block;
    margin-left: 1rem;
    background-image: url(static/assets/arrow-right-red.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 1.25rem;
    width: 0.75rem;
  }
}

.card__more--button {
  text-decoration: none;
  display: inline-block;
  color: var(--bern-red);
  border: 2px solid var(--bern-red);
  border-radius: var(--radius-small);
  font-weight: bold;
  white-space: nowrap;
  line-height: 1em;
  padding: 0.875rem 1.5rem;
  padding-right: 3rem;
  position: relative;
  margin-top: 1.25rem;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1.5rem;
    background-image: url(static/assets/arrow-right-red.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 1.25rem;
    width: 0.75rem;
  }
}

.card:hover,
.card:focus-visible {
  transform: scale(1.03);

  .card__more--button {
    text-decoration: none;
    color: #ffffff;
    background-color: var(--bern-red);
    &::after {
      background-image: url(static/assets/arrow-right-white.svg);
    }
  }
}

.card--hidden {
  order: 100;
  padding: 0 !important;
  transform: scale(0);
  & * {
    transform: scale(0);
    height: 0;
    width: 0;
  }
}

.card--yellow {
  background: linear-gradient(
    to bottom,
    var(--bern-yellow) 54px,
    var(--bern-white) 55px
  );
  transition:
    transform 1s,
    visibility 1s,
    margin 1s;
  &.card--hidden {
    margin-top: calc(-2 * var(--grid-gutter));
  }
}

.card--black {
  background: linear-gradient(
    to bottom,
    var(--bern-black) 54px,
    var(--bern-white) 55px
  );

  &::after {
    background-color: var(--bern-yellow);
  }
}

.card--red {
  background: linear-gradient(
    to bottom,
    var(--bern-red) 54px,
    var(--bern-white) 55px
  );
}

@import "./custom-media.css";

.ch__hints {
  display: grid;
  grid-template-columns: 1fr;
}

.ch__hint {
  grid-row-start: 1;
  grid-column-start: 1;
  position: relative;
  opacity: 0;
  z-index: 0;
  transition: all 0.5s linear;
  &.displayed {
    z-index: 1;
    opacity: 1;
  }
}

.ch__grid {
  display: grid;
  grid-template-columns: repeat(10, minmax(0, 1fr));
  grid-gap: var(--grid-gutter);
  gap: var(--grid-gutter);
  height: 100%;
}
.ch__button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;
  margin-bottom: 3.5rem;
}

.ch__button {
  border: 2px solid var(--bern-red);
  border-radius: 20px;
  padding: 0.375rem 1.375rem;
  display: inline-block;
  font-weight: 500;
  font-size: var(--fs-medium);
  color: var(--bern-red);
  cursor: pointer;
  line-height: 1em;
}

.ch__button.active {
  background: var(--bern-red);
  font-weight: bold;
  color: #ffffff;
  padding: 0.313rem 1.313rem;
}

.ch__image {
  display: block;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (--media-lg) {
  .ch__grid > .cell__content {
    grid-column-start: 7;
  }
}

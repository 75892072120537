@import "./custom-media.css";

h1,
h2,
h3,
h4,
h5,
h6 {
  > u {
    text-decoration: none;
    position: relative;
    display: inline-block;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 2px;
      height: 12px;
      background: url(static/assets/arc.svg) no-repeat center;
      background-size: 100% 100%;
      transform: translateY(100%);
    }
  }
}

@media (--media-mobile) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    > u::after {
      bottom: 4px;
      height: 8px;
    }
  }
}

@import "./custom-media.css";

.procedure__title {
  margin-bottom: 3rem;
}

.procedure__item {
  display: grid;
  margin-bottom: 1.25rem;
  grid-template-columns: repeat(10, minmax(0, 1fr));
  /* First cell is a square */
  grid-template-rows: 80px 1fr;
  grid-gap: 0.75rem;
  gap: 0.75rem;

  counter-increment: procedure;

  @media (--media-md) {
    grid-template-rows: 1fr 1fr;
  }

  & ul {
    list-style-type: disc;
  }
}

.procedure__item-number {
  background-color: var(--bern-yellow);
  position: relative;
  box-shadow: var(--shadow);
  border-radius: var(--radius);
  grid-column: span 10;

  @media (--media-md) {
    height: 100%;
  }

  &::before {
    content: counter(procedure);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    color: #ffffff;
    font-weight: bold;
    font-size: var(--fs-large);
    position: absolute;
    background-color: var(--bern-black);
    border-radius: 50%;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%) translateX(0);

    @media (--media-md) {
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
    }
  }
}

.procedure__item-content {
  background-image: var(--bern-white);
  box-shadow: var(--shadow);
  border-radius: var(--radius);
  grid-column: span 10;
  padding: 1.5rem 1rem;

  @media (--media-md) {
    padding: 1.5rem 4rem 1.5rem;
  }
}

.procedure__item-title {
  margin-bottom: 1rem;
}

.procedure__item-text {
  font-weight: 500;
  hyphens: auto;
}

@media (--media-md) {
  .procedure__item {
    grid-template-rows: 1fr;
  }

  .procedure__item-number {
    grid-column: span 3;
  }

  .procedure__item-content {
    grid-column: span 7;
  }
}

@media (--media-lg) {
  .procedure__item-number {
    grid-column: span 2;
  }

  .procedure__item-content {
    grid-column: span 8;
  }
}

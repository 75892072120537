.download__link {
  position: relative;
  gap: 1.875rem;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;

  &::before {
    content: "";
    display: block;
    background-image: url(static/assets/download.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 1.375rem;
    background-color: var(--bern-red);
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    flex: 1 0 2rem;
  }
}

.download__title {
  font-size: var(--fs-medium);
  font-weight: bold;
  line-height: 1.5em;
  hyphens: auto;
}

.download__description {
  line-height: 1.5em;
}
